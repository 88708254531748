import React from "react"

import Layout from "@/components/Layout"
import Seo from "@/components/seo"
import PageHeading from "@/components/PageHeading"
import PageIntro from "@/components/PageIntro"
import HireUsSlider from "@/components/HireUsSlider"
import ContactForm from "@/components/ContactForm"
import { Link } from "gatsby"

const HireUsPage = () => {
  return (
    <Layout>
      <Seo
        title="Expert Cloud Consulting Services: Hire Our Agency for Your Business Needs"
        description="Maximize your success in the cloud with our expert consulting services. From cloud infrastructure and application development to training and support, we have the skills and experience to guide you every step of the way. Let us work with you to achieve your business goals. Contact us to learn more and hire our agency."
      />
      <div data-aos="fade-up">
        <PageHeading
          title="Hire Us"
          description="Ready to begin? Let's gather some information about your project and needs."
        />
      </div>
      <div data-aos="fade-up">
        <PageIntro>
          <span>
            Easesol aims to transform how businesses use the cloud by
            simplifying the technology and providing an easy path to move into
            the cloud.
          </span>
        </PageIntro>
      </div>
      <section className="w-full mb-24 hire-form-section">
        <div className="container mx-auto">
          <div className="flex flex-col-reverse items-center justify-center xl:flex-row gap-x-5 gap-y-16">
            <div
              className="w-full max-w-xl py-10 mx-auto xl:w-2/5 2xl:w-1/3"
              data-aos="fade-up"
            >
              {/* <HireUsForm></HireUsForm> */}
              {/* <ContactForm/> */}
              <div
        className="container flex flex-wrap justify-center mx-auto my-16"
        data-aos="fade-up"
      >
        <span className="text-3xl font-semibold leading-relaxed md:text-4xl lg:text-5xl text-secondary-600">
          Reach Us at
        </span>
      </div>
              <div
        className="container flex flex-wrap justify-center mx-auto my-16"
        data-aos="fade-up"
      >
        <div className="text-2xl font-semibold leading-relaxed text-primary-700 dark:text-primary-300 flex-inline md:text-3xl lg:text-4xl">
         
          <Link
            className="font-bold flex-inline text-secondary-600"
            to="mailto: info@easesol.net"
          >
            info@easesol.net
          </Link>
        </div>
      </div>
            </div>
            <div
              className="relative flex items-center justify-center w-full overflow-hidden xl:w-3/5 2xl:w-2/3"
              data-aos="fade-up"
            >
              <HireUsSlider />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default HireUsPage
